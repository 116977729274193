import dayjs from 'dayjs';
import { HeatmapItemType } from '~/shared/ui/chart/heatmap/types/heatmap-item.type';
import { HeatmapTrendType } from '~/shared/ui/chart/heatmap/types/heatmap-trend.type';
import { HeatmapColumnsEnum } from '@/packages/productivity/enums/heatmap-columns.enum';
import { IHeatmapDto } from '~/shared/ui/chart/heatmap/types/i-heatmap-dto';
import { FilterKeyEnum } from '@/packages/filter/enums/filter-key.enum';

export class HeatmapFakeService {
  public static getHeatmapData(dto: IHeatmapDto): Promise<HeatmapItemType[]> {
    const map = HeatmapFakeService.getHeatmapDataMap();

    if (dto.grouping && dto.grouping in map /* && !('groupingId' in dto)*/) {
      const response = map[dto.grouping as FilterKeyEnum] as HeatmapItemType[];
      return Promise.resolve(response);
    }

    return Promise.resolve([]);
  }

  public static getHeatmapCellTrendData(): Promise<HeatmapTrendType> {
    return Promise.resolve({
      [HeatmapColumnsEnum.MESSAGES_DIFFERENCE]: [
        {
          value: -10,
          date: dayjs().subtract(7, 'month').endOf('month').toISOString(),
        },
        {
          value: 3,
          date: dayjs().subtract(6, 'month').endOf('month').toISOString(),
        },
        {
          value: -16,
          date: dayjs().subtract(5, 'month').endOf('month').toISOString(),
        },
        {
          value: 50,
          date: dayjs().subtract(4, 'month').endOf('month').toISOString(),
        },
        {
          value: 34,
          date: dayjs().subtract(3, 'month').endOf('month').toISOString(),
        },
        {
          value: 23,
          date: dayjs().subtract(2, 'month').endOf('month').toISOString(),
        },
        {
          value: -40,
          date: dayjs().subtract(1, 'month').endOf('month').toISOString(),
        },
      ],
      [HeatmapColumnsEnum.VIDEO_CALLS_DIFFERENCE]: [
        {
          value: -10,
          date: dayjs().subtract(7, 'month').endOf('month').toISOString(),
        },
        {
          value: 3,
          date: dayjs().subtract(6, 'month').endOf('month').toISOString(),
        },
        {
          value: -16,
          date: dayjs().subtract(5, 'month').endOf('month').toISOString(),
        },
        {
          value: 50,
          date: dayjs().subtract(4, 'month').endOf('month').toISOString(),
        },
        {
          value: 34,
          date: dayjs().subtract(3, 'month').endOf('month').toISOString(),
        },
        {
          value: 10,
          date: dayjs().subtract(2, 'month').endOf('month').toISOString(),
        },
        {
          value: -40,
          date: dayjs().subtract(1, 'month').endOf('month').toISOString(),
        },
      ],
      [HeatmapColumnsEnum.EMAILS_DIFFERENCE]: [
        {
          value: -10,
          date: dayjs().subtract(7, 'month').endOf('month').toISOString(),
        },
        {
          value: 3,
          date: dayjs().subtract(6, 'month').endOf('month').toISOString(),
        },
        {
          value: -16,
          date: dayjs().subtract(5, 'month').endOf('month').toISOString(),
        },
        {
          value: 50,
          date: dayjs().subtract(4, 'month').endOf('month').toISOString(),
        },
        {
          value: 34,
          date: dayjs().subtract(3, 'month').endOf('month').toISOString(),
        },
        {
          value: 10,
          date: dayjs().subtract(2, 'month').endOf('month').toISOString(),
        },
        {
          value: -40,
          date: dayjs().subtract(1, 'month').endOf('month').toISOString(),
        },
      ],
      [HeatmapColumnsEnum.MEETINGS_DIFFERENCE]: [
        {
          value: -10,
          date: dayjs().subtract(7, 'month').endOf('month').toISOString(),
        },
        {
          value: 3,
          date: dayjs().subtract(6, 'month').endOf('month').toISOString(),
        },
        {
          value: -16,
          date: dayjs().subtract(5, 'month').endOf('month').toISOString(),
        },
        {
          value: 50,
          date: dayjs().subtract(4, 'month').endOf('month').toISOString(),
        },
        {
          value: 34,
          date: dayjs().subtract(3, 'month').endOf('month').toISOString(),
        },
        {
          value: 10,
          date: dayjs().subtract(2, 'month').endOf('month').toISOString(),
        },
        {
          value: -40,
          date: dayjs().subtract(1, 'month').endOf('month').toISOString(),
        },
      ],
    });
  }

  public static getHeatmapDataMap(): Partial<Record<FilterKeyEnum, HeatmapItemType[]>> {
    return {
      [FilterKeyEnum.PERFORMANCE]: [
        {
          id: 0,
          isAffected: false,
          title: 'Top performers',
          employeesCount: 230,
          meetings: 5,
          videoCalls: 7,
          sentEmails: 60,
          messages: 80,
          meetingsDifference: 15,
          videoCallsDifference: 25,
          emailsDifference: -10,
          messagesDifference: 33,
          absent: 4,
          newHires: 12,
          leavers: 5,
        },
        {
          id: 1,
          isAffected: false,
          title: 'Mid performers',
          employeesCount: 480,
          meetings: 10,
          videoCalls: 12,
          sentEmails: 90,
          messages: 120,
          meetingsDifference: 10,
          videoCallsDifference: 15,
          emailsDifference: -5,
          messagesDifference: 20,
          absent: 12,
          newHires: 35,
          leavers: 8,
        },
        {
          id: 2,
          isAffected: false,
          title: 'Bottom performers',
          employeesCount: 122,
          meetings: 2,
          videoCalls: 2,
          sentEmails: 10,
          messages: 15,
          meetingsDifference: 40,
          videoCallsDifference: 33,
          emailsDifference: -25,
          messagesDifference: -33,
          absent: 8,
          newHires: 2,
          leavers: 4,
        },
      ],

      [FilterKeyEnum.QUIET_QUITTERS]: [
        {
          id: 0,
          title: 'Quiet quitters',
          employeesCount: 400,
          isAffected: false,
          meetings: 2.7,
          videoCalls: 4.4,
          sentEmails: 7,
          messages: 38.7,
          meetingsDifference: -13,
          videoCallsDifference: 27,
          emailsDifference: -19,
          messagesDifference: 36,
          absent: 6,
          newHires: 3,
          leavers: 1,
        },
      ],

      [FilterKeyEnum.GENDER]: [
        {
          id: 0,
          title: 'Male',
          employeesCount: 400,
          isAffected: false,
          meetings: 2.5,
          videoCalls: 2.1,
          sentEmails: 14.9,
          messages: 12.8,
          meetingsDifference: -14,
          videoCallsDifference: -24,
          emailsDifference: -27,
          messagesDifference: -18,
          absent: 5,
          newHires: 11,
          leavers: 2,
        },
        {
          id: 1,
          title: 'Female',
          employeesCount: 400,
          isAffected: false,
          meetings: 5.6,
          videoCalls: 2.3,
          sentEmails: 20.4,
          messages: 16.7,
          meetingsDifference: 25,
          videoCallsDifference: 12,
          emailsDifference: 23,
          messagesDifference: -3,
          absent: 3,
          newHires: 9,
          leavers: 7,
        },
        {
          id: 2,
          title: 'Not set',
          employeesCount: 400,
          isAffected: false,
          meetings: 1.8,
          videoCalls: 2.1,
          sentEmails: 12.4,
          messages: 9.3,
          meetingsDifference: -7,
          videoCallsDifference: -12,
          emailsDifference: 15,
          messagesDifference: -2,
          absent: 2,
          newHires: 14,
          leavers: 10,
        },
      ],

      [FilterKeyEnum.GENERATION]: [
        {
          id: 0,
          title: 'Z or Zoomers (1997-2012)',
          employeesCount: 400,
          isAffected: false,
          meetings: 1.5,
          videoCalls: 2.3,
          sentEmails: 14.6,
          messages: 10.3,
          meetingsDifference: 26,
          videoCallsDifference: 21,
          emailsDifference: -17,
          messagesDifference: 35,
          absent: 7,
          newHires: 14,
          leavers: 11,
        },
        {
          id: 1,
          title: 'Y or Milennials (1981-1996)',
          employeesCount: 400,
          isAffected: false,
          meetings: 1.5,
          videoCalls: 2.1,
          sentEmails: 12,
          messages: 12.6,
          meetingsDifference: 14,
          videoCallsDifference: -7,
          emailsDifference: -16,
          messagesDifference: -9,
          absent: 1,
          newHires: 3,
          leavers: 8,
        },
        {
          id: 2,
          title: 'X or Xennials (1965-1980)',
          employeesCount: 400,
          isAffected: false,
          meetings: 2.1,
          videoCalls: 2.5,
          sentEmails: 23.7,
          messages: 16.6,
          meetingsDifference: 2,
          videoCallsDifference: -9,
          emailsDifference: 20,
          messagesDifference: -3,
          absent: 2,
          newHires: 1,
          leavers: 6,
        },
        {
          id: 3,
          title: 'Baby boomers (1946-1964)',
          employeesCount: 400,
          isAffected: false,
          meetings: 2,
          videoCalls: 2.4,
          sentEmails: 23.7,
          messages: 11,
          meetingsDifference: 6,
          videoCallsDifference: -11,
          emailsDifference: 27,
          messagesDifference: 4,
          absent: 2,
          newHires: 0,
          leavers: 0,
        },
        {
          id: 4,
          title: 'Not set',
          employeesCount: 400,
          isAffected: false,
          meetings: 0.8,
          videoCalls: 1.5,
          sentEmails: 8.3,
          messages: 3.4,
          meetingsDifference: 13,
          videoCallsDifference: -7,
          emailsDifference: -10,
          messagesDifference: -28,
          absent: 1,
          newHires: 4,
          leavers: 2,
        },
      ],

      [FilterKeyEnum.PAY_INCREASE]: [
        {
          id: 0,
          title: '0-3 months',
          employeesCount: 400,
          isAffected: false,
          meetings: 1.9,
          videoCalls: 2.2,
          sentEmails: 11,
          messages: 11.4,
          meetingsDifference: -12,
          videoCallsDifference: -11,
          emailsDifference: -17,
          messagesDifference: -7,
          absent: 0,
          newHires: 7,
          leavers: 3,
        },
        {
          id: 1,
          title: '3-6 months',
          employeesCount: 400,
          isAffected: false,
          meetings: 1.5,
          videoCalls: 2.2,
          sentEmails: 15,
          messages: 13,
          meetingsDifference: -2,
          videoCallsDifference: -6.5,
          emailsDifference: -13,
          messagesDifference: -5,
          absent: 1,
          newHires: 2,
          leavers: 1,
        },
        {
          id: 2,
          title: '6-9 months',
          employeesCount: 400,
          isAffected: false,
          meetings: 1.9,
          videoCalls: 2.4,
          sentEmails: 20,
          messages: 11.5,
          meetingsDifference: -3.5,
          videoCallsDifference: -11,
          emailsDifference: -15,
          messagesDifference: -13.7,
          absent: 2,
          newHires: 0,
          leavers: 2,
        },
        {
          id: 3,
          title: '9-12 months',
          employeesCount: 400,
          isAffected: false,
          meetings: 1.6,
          videoCalls: 2.1,
          sentEmails: 15.7,
          messages: 14.3,
          meetingsDifference: 6.8,
          videoCallsDifference: -2.3,
          emailsDifference: -7,
          messagesDifference: -4,
          absent: 5,
          newHires: 0,
          leavers: 5,
        },
        {
          id: 4,
          title: '12-18 months',
          employeesCount: 400,
          isAffected: false,
          meetings: 1.7,
          videoCalls: 2.2,
          sentEmails: 14,
          messages: 15,
          meetingsDifference: 13,
          videoCallsDifference: -7,
          emailsDifference: -10,
          messagesDifference: -28,
          absent: 0,
          newHires: 0,
          leavers: 4,
        },
        {
          id: 5,
          title: '18-24 months',
          employeesCount: 400,
          isAffected: false,
          meetings: 2.6,
          videoCalls: 2.7,
          sentEmails: 17,
          messages: 22,
          meetingsDifference: 7,
          videoCallsDifference: 9,
          emailsDifference: 5.5,
          messagesDifference: 8.7,
          absent: 0,
          newHires: 0,
          leavers: 1,
        },
        {
          id: 6,
          title: '24-30 months',
          employeesCount: 400,
          isAffected: false,
          meetings: 3.2,
          videoCalls: 4.3,
          sentEmails: 33.8,
          messages: 27.2,
          meetingsDifference: 23,
          videoCallsDifference: 33,
          emailsDifference: 13,
          messagesDifference: 27,
          absent: 1,
          newHires: 6,
          leavers: 1,
        },
        {
          id: 7,
          title: '30-36 months',
          employeesCount: 400,
          isAffected: false,
          meetings: 1.9,
          videoCalls: 2.1,
          sentEmails: 25,
          messages: 14,
          meetingsDifference: 3.2,
          videoCallsDifference: 4,
          emailsDifference: -3.5,
          messagesDifference: -5.5,
          absent: 2,
          newHires: 1,
          leavers: 2,
        },
      ],

      [FilterKeyEnum.PROMOTION]: [
        {
          id: 0,
          title: '0-3 months',
          employeesCount: 120,
          isAffected: false,
          meetings: 1.9,
          videoCalls: 2.2,
          sentEmails: 11,
          messages: 11.4,
          meetingsDifference: -12,
          videoCallsDifference: -11,
          emailsDifference: -17,
          messagesDifference: -7,
          absent: 0,
          newHires: 7,
          leavers: 3,
        },
        {
          id: 1,
          title: '3-6 months',
          employeesCount: 70,
          isAffected: false,
          meetings: 1.5,
          videoCalls: 2.2,
          sentEmails: 15,
          messages: 13,
          meetingsDifference: -2,
          videoCallsDifference: -6.5,
          emailsDifference: -13,
          messagesDifference: -5,
          absent: 1,
          newHires: 2,
          leavers: 1,
        },
        {
          id: 2,
          title: '6-9 months',
          employeesCount: 88,
          isAffected: false,
          meetings: 1.9,
          videoCalls: 2.4,
          sentEmails: 20,
          messages: 11.5,
          meetingsDifference: -3.5,
          videoCallsDifference: -11,
          emailsDifference: -15,
          messagesDifference: -13.7,
          absent: 2,
          newHires: 0,
          leavers: 2,
        },
        {
          id: 3,
          title: '9-12 months',
          employeesCount: 72,
          isAffected: false,
          meetings: 1.6,
          videoCalls: 2.1,
          sentEmails: 15.7,
          messages: 14.3,
          meetingsDifference: 6.8,
          videoCallsDifference: -2.3,
          emailsDifference: -7,
          messagesDifference: -4,
          absent: 5,
          newHires: 0,
          leavers: 5,
        },
        {
          id: 4,
          title: '12-18 months',
          employeesCount: 49,
          isAffected: false,
          meetings: 1.7,
          videoCalls: 2.2,
          sentEmails: 14,
          messages: 15,
          meetingsDifference: 13,
          videoCallsDifference: -7,
          emailsDifference: -10,
          messagesDifference: -28,
          absent: 0,
          newHires: 0,
          leavers: 4,
        },
        {
          id: 5,
          title: '18-24 months',
          employeesCount: 37,
          isAffected: false,
          meetings: 2.6,
          videoCalls: 2.7,
          sentEmails: 17,
          messages: 22,
          meetingsDifference: 7,
          videoCallsDifference: 9,
          emailsDifference: 5.5,
          messagesDifference: 8.7,
          absent: 0,
          newHires: 0,
          leavers: 1,
        },
        {
          id: 6,
          title: '24-30 months',
          employeesCount: 25,
          isAffected: false,
          meetings: 3.2,
          videoCalls: 4.3,
          sentEmails: 33.8,
          messages: 27.2,
          meetingsDifference: 23,
          videoCallsDifference: 33,
          emailsDifference: 13,
          messagesDifference: 27,
          absent: 1,
          newHires: 6,
          leavers: 1,
        },
        {
          id: 7,
          title: '30-36 months',
          employeesCount: 38,
          isAffected: false,
          meetings: 1.9,
          videoCalls: 2.1,
          sentEmails: 25,
          messages: 14,
          meetingsDifference: 3.2,
          videoCallsDifference: 4,
          emailsDifference: -3.5,
          messagesDifference: -5.5,
          absent: 2,
          newHires: 1,
          leavers: 2,
        },
      ],

      [FilterKeyEnum.CITY]: [
        {
          id: 'qwerty',
          isAffected: false,
          title: 'New York',
          employeesCount: 150,
          meetings: 7,
          videoCalls: 6,
          sentEmails: 60,
          messages: 88,
          meetingsDifference: 15,
          videoCallsDifference: 25,
          emailsDifference: -10,
          messagesDifference: 33,
          absent: 4,
          newHires: 12,
          leavers: 5,
        },
        {
          id: 1,
          isAffected: false,
          title: 'San Francisco',
          employeesCount: 433,
          meetings: 5,
          videoCalls: 4,
          sentEmails: 80,
          messages: 50,
          meetingsDifference: 10,
          videoCallsDifference: 15,
          emailsDifference: -5,
          messagesDifference: 20,
          absent: 12,
          newHires: 35,
          leavers: 8,
        },
        {
          id: 2,
          isAffected: false,
          title: 'Denver',
          employeesCount: 88,
          meetings: 2,
          videoCalls: 2,
          sentEmails: 10,
          messages: 10,
          meetingsDifference: 40,
          videoCallsDifference: 33,
          emailsDifference: -25,
          messagesDifference: -33,
          absent: 8,
          newHires: 2,
          leavers: 4,
        },
        {
          id: 3,
          isAffected: false,
          title: 'Austin',
          employeesCount: 37,
          meetings: 1,
          videoCalls: 1,
          sentEmails: 5,
          messages: 5,
          meetingsDifference: -80,
          videoCallsDifference: -60,
          emailsDifference: 40,
          messagesDifference: 20,
          absent: 0,
          newHires: 8,
          leavers: 0,
        },
      ],

      [FilterKeyEnum.DEPARTMENT]: [
        {
          id: 0,
          isAffected: false,
          title: 'Sales',
          employeesCount: 320,
          meetings: 7,
          videoCalls: 6,
          sentEmails: 60,
          messages: 88,
          meetingsDifference: 15,
          videoCallsDifference: 25,
          emailsDifference: -10,
          messagesDifference: 33,
          absent: 4,
          newHires: 12,
          leavers: 5,
        },
        {
          id: 1,
          isAffected: false,
          title: 'Engineering',
          employeesCount: 588,
          meetings: 5,
          videoCalls: 4,
          sentEmails: 80,
          messages: 50,
          meetingsDifference: 10,
          videoCallsDifference: 15,
          emailsDifference: -5,
          messagesDifference: 20,
          absent: 12,
          newHires: 35,
          leavers: 8,
        },
        {
          id: 2,
          isAffected: false,
          title: 'HR',
          employeesCount: 76,
          meetings: 2,
          videoCalls: 2,
          sentEmails: 10,
          messages: 10,
          meetingsDifference: 40,
          videoCallsDifference: 33,
          emailsDifference: -25,
          messagesDifference: -33,
          absent: 8,
          newHires: 2,
          leavers: 4,
        },
        {
          id: 3,
          isAffected: false,
          title: 'Marketing',
          employeesCount: 120,
          meetings: 1,
          videoCalls: 1,
          sentEmails: 5,
          messages: 5,
          meetingsDifference: -80,
          videoCallsDifference: -60,
          emailsDifference: 40,
          messagesDifference: 20,
          absent: 0,
          newHires: 8,
          leavers: 0,
        },
      ],

      [FilterKeyEnum.TEAM_MANAGER]: [
        {
          title: 'Gavin Belson',
          employeesCount: 87,
          isAffected: false,
          id: 1,

          meetings: 35,
          videoCalls: 41,
          sentEmails: 45,
          messages: 51,

          meetingsDifference: -25,
          videoCallsDifference: -21,
          emailsDifference: -31,
          messagesDifference: -55,

          absent: 20,
          newHires: 15,
          leavers: 5,
        },
        {
          title: 'Richard Hendricks',
          employeesCount: 99,
          isAffected: false,
          id: 2,

          meetings: 40,
          videoCalls: 33,
          sentEmails: 2,
          messages: 4,

          meetingsDifference: 8,
          videoCallsDifference: 17,
          emailsDifference: 3,
          messagesDifference: 3,

          absent: 3,
          newHires: 21,
          leavers: 39,
        },
        {
          title: 'Dinesh Chugtai',
          employeesCount: 64,
          isAffected: false,
          id: 3,

          meetings: 10,
          videoCalls: 3,
          sentEmails: 8,
          messages: 6,

          meetingsDifference: 8,
          videoCallsDifference: 7,
          emailsDifference: 4,
          messagesDifference: 2,

          absent: 0,
          newHires: 64,
          leavers: 0,
        },
        {
          title: 'Jack Barker',
          employeesCount: 32,
          isAffected: false,
          id: 4,

          meetings: 33,
          videoCalls: 32,
          sentEmails: 23,
          messages: 29,

          meetingsDifference: -9,
          videoCallsDifference: -9,
          emailsDifference: 7,
          messagesDifference: 10,

          absent: 18,
          newHires: 36,
          leavers: 13,
        },
        {
          title: 'Bertram Gilfoyle',
          employeesCount: 56,
          isAffected: false,
          id: 5,

          meetings: 99,
          videoCalls: 103,
          sentEmails: 88,
          messages: 76,

          meetingsDifference: -25,
          videoCallsDifference: -25,
          emailsDifference: -25,
          messagesDifference: -25,

          absent: 4,
          newHires: 50,
          leavers: 35,
        },
      ],

      [FilterKeyEnum.USER]: [
        {
          title: 'Gavin Belson',
          employeesCount: 87,
          isAffected: false,
          id: 1,

          meetings: 35,
          videoCalls: 41,
          sentEmails: 45,
          messages: 51,

          meetingsDifference: -25,
          videoCallsDifference: -21,
          emailsDifference: -31,
          messagesDifference: -55,

          absent: 20,
          newHires: 15,
          leavers: 5,
        },
        {
          title: 'Richard Hendricks',
          employeesCount: 99,
          isAffected: false,
          id: 2,

          meetings: 40,
          videoCalls: 33,
          sentEmails: 2,
          messages: 4,

          meetingsDifference: 8,
          videoCallsDifference: 17,
          emailsDifference: 3,
          messagesDifference: 3,

          absent: 3,
          newHires: 21,
          leavers: 39,
        },
        {
          title: 'Dinesh Chugtai',
          employeesCount: 64,
          isAffected: false,
          id: 3,

          meetings: 10,
          videoCalls: 3,
          sentEmails: 8,
          messages: 6,

          meetingsDifference: 8,
          videoCallsDifference: 7,
          emailsDifference: 4,
          messagesDifference: 2,

          absent: 0,
          newHires: 64,
          leavers: 0,
        },
        {
          title: 'Jack Barker',
          employeesCount: 32,
          isAffected: false,
          id: 4,

          meetings: 33,
          videoCalls: 32,
          sentEmails: 23,
          messages: 29,

          meetingsDifference: -9,
          videoCallsDifference: -9,
          emailsDifference: 7,
          messagesDifference: 10,

          absent: 18,
          newHires: 36,
          leavers: 13,
        },
        {
          title: 'Bertram Gilfoyle',
          employeesCount: 56,
          isAffected: false,
          id: 5,

          meetings: 99,
          videoCalls: 103,
          sentEmails: 88,
          messages: 76,

          meetingsDifference: -25,
          videoCallsDifference: -25,
          emailsDifference: -25,
          messagesDifference: -25,

          absent: 4,
          newHires: 50,
          leavers: 35,
        },
      ],
    };
  }
}
