import { PermissionEnum } from '@/packages/user/enums/permission.enums';
import { SessionUserType } from '@/packages/user/types/auth.types';
import { LoginTypeEnum } from '@/packages/user/enums/auth.enums';
import { OrganisationType } from '@/packages/user/types/organisation.types';
import { OrganisationSubscriptionEnum } from '@/packages/user/enums/organisation.enums';

export class UserSessionFakeService {
  private static demoAccessTokenKey = 'demoAccessToken';

  static getAuthOptions(): Promise<{ options: LoginTypeEnum[] }> {
    return Promise.resolve({
      options: [LoginTypeEnum.PERFORMETRY_SIGN_IN],
    });
  }

  static getUserOrganisation(): Promise<OrganisationType> {
    return Promise.resolve({
      id: '1',
      name: 'Performetry',
      domains: [],
      subscription: {
        uid: OrganisationSubscriptionEnum.INTEGRATION_ANALYTICS,
        modules: [],
      },
      timezone: null,
      logo: undefined,
    });
  }

  static async getAdmin(): Promise<SessionUserType> {
    const org = await UserSessionFakeService.getUserOrganisation();
    const config = useRuntimeConfig();

    const removedPermissions: (keyof typeof PermissionEnum)[] = [
      PermissionEnum.ATTRITION_HEATMAP_VIEW_USERS,
      PermissionEnum.ATTRITION_ALERT_HEATMAP_VIEW_USERS,
      PermissionEnum.PRODUCTIVITY_HEATMAP_VIEW_USERS,
    ];
    const permissions = Object.values(PermissionEnum).filter(p => !removedPermissions.includes(p));

    return Promise.resolve({
      id: '1',
      email: config.demoAdminEmail || '',
      firstName: 'Admin',
      lastName: 'Performetry',
      pictures: '',
      organisation: {
        id: 1,
        name: org.name,
      },
      role: {
        id: 1,
        uid: '',
        name: '',
        permissions,
      },
    });
  }

  static async getManager(): Promise<SessionUserType> {
    const org = await UserSessionFakeService.getUserOrganisation();
    const config = useRuntimeConfig();

    return Promise.resolve({
      id: '2',
      email: config.demoManagerEmail || '',
      firstName: 'Manager',
      lastName: 'Performetry',
      pictures: '',
      organisation: {
        id: 1,
        name: org.name,
      },
      role: {
        id: 2,
        uid: '',
        name: '',
        permissions: [
          PermissionEnum.SURVEY_REPLY,
          PermissionEnum.SURVEY_VIEW,

          PermissionEnum.ATTRITION_HEATMAP_VIEW,
          PermissionEnum.ATTRITION_HEATMAP_VIEW_TREND,

          PermissionEnum.PRODUCTIVITY_HEATMAP_VIEW,
          PermissionEnum.PRODUCTIVITY_HEATMAP_VIEW_TREND,
        ],
      },
    });
  }

  static async getEmployee(): Promise<SessionUserType> {
    const org = await UserSessionFakeService.getUserOrganisation();
    const config = useRuntimeConfig();

    return Promise.resolve({
      id: '3',
      email: config.demoEmployeeEmail || '',
      firstName: 'Employee',
      lastName: 'Performetry',
      pictures: '',
      organisation: {
        id: 1,
        name: org.name,
      },
      role: {
        id: 2,
        uid: '',
        name: '',
        permissions: [PermissionEnum.SURVEY_REPLY, PermissionEnum.SURVEY_VIEW],
      },
    });
  }

  static setToken(token: string) {
    localStorage.setItem(UserSessionFakeService.demoAccessTokenKey, token);
  }

  static getToken(): string | null {
    return localStorage.getItem(UserSessionFakeService.demoAccessTokenKey);
  }

  static logout(): Promise<any> {
    localStorage.removeItem(UserSessionFakeService.demoAccessTokenKey);

    return Promise.resolve();
  }
}
