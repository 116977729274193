import { useFilterStore } from './filter.store';
import { FilterService } from '@/packages/filter/filter.service';
import { AppFacade } from '~/app/app.facade';
import { FilterOptionType } from '@/packages/filter/types/filter-option.type';
import { FilteredUsersRequestDto, FiltersRequestDto } from '@/packages/filter/types/filters-request-dto.type';
import { FilteredUsersType } from '@/packages/filter/types/filtered-users.type';
import { Cache } from '~/shared/helpers/cache';

export class FilterFacade extends AppFacade {
  // private readonly MINIMUM_USERS = 3;
  readonly service = new FilterService();
  readonly store;

  constructor() {
    super();
    this.store = useFilterStore();
  }

  async fetchFiltersAndStoreIfNeeded(dto: FiltersRequestDto): Promise<Record<string, FilterOptionType>> {
    const key = Cache.generateKey(dto);

    if (!this.store.filtersCache.hasItem(key)) {
      const filters = await this.service.fetchFilters(dto);
      this.store.cacheFilters({ key, filters });
    }

    return this.store.filtersCache.getItem(key) as Record<string, FilterOptionType>;
  }

  async fetchFilteredUsersAndStoreIfNeeded(dto: FilteredUsersRequestDto): Promise<FilteredUsersType> {
    const key = Cache.generateKey(dto);

    if (!this.store.filteredUsersCache.hasItem(key)) {
      const users = await this.service.fetchFilteredUsers(dto);
      this.store.cacheFilteredUsers({ key, users });
    }

    return this.store.filteredUsersCache.getItem(key) as FilteredUsersType;
  }

  getMinimumUsers(): number {
    // return userCan(PermissionEnum.VIEW_ALL_COMPANY_DATA) ? this.MINIMUM_USERS : 0;
    return 0;
  }
}
