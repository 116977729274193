import { AppService } from '~/app/app.service';
import { Faker } from '@/packages/fake-data/faker';
import { TrendFakeService } from '@/packages/fake-data/productivity/trend.fake-service';
import { Trend, TrendMetricGroupCollection } from '~/shared/ui/chart/trend/types/trend.types';
import { TrendFetchDto } from '~/shared/ui/chart/trend/types/trend-request.types';

export class TrendsService extends AppService {
  public async fetchMetricsTrend(options: TrendFetchDto): Promise<Record<string, Trend>> {
    const { period, metrics, targeting, products, periodGrouping } = options;

    if (Faker.isEnabled()) {
      return TrendFakeService.fetchMetricsData(options);
    }

    const response = await this.api.core.post(`/productivity/trend`, {
      period,
      metrics,
      targeting,
      products,
      periodGrouping,
    });

    return response.data.payload.data;
  }

  public async fetchMetricsDescription(): Promise<TrendMetricGroupCollection> {
    if (Faker.isEnabled()) {
      return TrendFakeService.fetchMetricsDescription();
    }

    const response = await this.api.core.get(`/productivity/trend/metric`);

    return response.data.payload.metrics;
  }
}
