import { FilterCriteria } from '@/packages/filter/types/filter-criteria.type';
import { AppService } from '~/app/app.service';
import { Faker } from '@/packages/fake-data/faker';
import { HeatmapFakeService } from '@/packages/fake-data/productivity/heatmap.fake-service';
import { HeatmapItemType } from '~/shared/ui/chart/heatmap/types/heatmap-item.type';
import { HeatmapTrendType } from '~/shared/ui/chart/heatmap/types/heatmap-trend.type';
import { IntegrationEnum } from '@/packages/integration/enums/integration.enum';
import { HeatmapColumnsEnum } from '@/packages/productivity/enums/heatmap-columns.enum';
import { DatePickerPeriodEnum } from '~/shared/ui/form/date-picker/enums/date-picker-period.enum';
import { IHeatmapDto } from '~/shared/ui/chart/heatmap/types/i-heatmap-dto';
import { FilterKeyEnum } from '~/packages/filter/enums/filter-key.enum';
import { isUndefined } from '~/shared/helpers/is-undefined';

export class HeatmapService extends AppService {
  public fetchHeatmapData(options: IHeatmapDto, isDemo = false): Promise<HeatmapItemType[]> {
    if (Faker.isEnabled() || isDemo) {
      return HeatmapFakeService.getHeatmapData(options);
    }

    const {
      starts,
      ends,
      // groupingId,
      targeting,
      parentGrouping,
      grouping,
      period = DatePickerPeriodEnum.MONTH,
      products = [],
    } = options;

    const dto: IHeatmapDto = {
      grouping,
      targeting,
      period,
      starts,
      ends,
      products,
    };

    // if (groupingId) dto.groupingId = groupingId;

    if (!isUndefined(parentGrouping)) {
      dto.parentGrouping = parentGrouping;
    }

    return this.api.core.post(`/productivity/heatmap`, dto).then((response: any) => response.data.payload.data);
  }

  public fetchHeatmapCellTrendData(
    options: {
      starts: string;
      ends: string;
      grouping?: FilterKeyEnum;
      period?: DatePickerPeriodEnum;
      metric: string;
      id: string;
      targeting: FilterCriteria;
      products?: IntegrationEnum[];
      isUser?: boolean;
    },
    requestOptions = {}
  ): Promise<HeatmapTrendType> {
    if (Faker.isEnabled()) {
      return HeatmapFakeService.getHeatmapCellTrendData();
    }

    const {
      starts,
      ends,
      metric,
      id,
      targeting,
      grouping,
      period = DatePickerPeriodEnum.MONTH,
      products = [],
      isUser = false,
    } = options;

    const columnKeys: HeatmapColumnsEnum[] = [
      HeatmapColumnsEnum.MEETINGS_DIFFERENCE,
      HeatmapColumnsEnum.VIDEO_CALLS_DIFFERENCE,
      HeatmapColumnsEnum.EMAILS_DIFFERENCE,
      HeatmapColumnsEnum.MESSAGES_DIFFERENCE,
    ];

    return this.api.core
      .post(
        `/productivity/heatmap/trend`,
        {
          grouping,
          period,
          starts,
          ends,
          metric,
          id,
          products,
          targeting,
          isUser,
        },
        requestOptions
      )
      .then((response: any) =>
        response.data.payload.data.reduce((acc: HeatmapTrendType, item: Record<string, any>) => {
          columnKeys.forEach(columnKey => {
            if (!acc[columnKey]) {
              acc[columnKey] = [];
            }

            acc[columnKey]?.push({
              date: item.date,
              value: item[columnKey],
            });
          });

          return acc;
        }, {})
      );
  }
}
