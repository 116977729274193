import { FilterKeyEnum } from '@/packages/filter/enums/filter-key.enum';
import { FilterCriteria } from '@/packages/filter/types/filter-criteria.type';
import { defineStore } from 'pinia';
import { DatesRangeType } from '~/shared/ui/form/date-picker/types/dates-range.type';
import { Cache } from '~/shared/helpers/cache';
import { HeatmapItemType } from '~/shared/ui/chart/heatmap/types/heatmap-item.type';
import { DatePickerOptionType } from '~/shared/ui/form/date-picker/types/date-picker-option.type';
import { useDatesStore } from '~/shared/stores/dates.store';

export const useAttritionHeatmapStore = defineStore('attritionHeatmap', {
  state: () => ({
    cache: new Cache<HeatmapItemType[]>(),
    lastFetchedGrouping: '' as FilterKeyEnum,
    grouping: '' as FilterKeyEnum,
    filters: {} as FilterCriteria,
  }),

  getters: {
    datepicker() {
      return {
        selectedOption: useDatesStore().selectedOption,
      };
    },

    dates(): DatesRangeType | undefined {
      return useDatesStore().selectedDates;
    },

    datesDisplay(): string {
      return useDatesStore().selectedOption?.title || '';
    },

    activeHeatmap(): HeatmapItemType[] | undefined {
      return this.cache.getItem(this.activeCacheKey);
    },

    hasCachedActive(): boolean {
      return this.cache.hasItem(this.activeCacheKey);
    },

    activeCacheKey(): string {
      return Cache.generateKey({ d: this.dates, g: this.grouping, f: this.filters });
    },

    getActiveCacheKeyForRow(): (rowId: string | number) => string {
      return rowId =>
        Cache.generateKey({
          h: this.activeCacheKey,
          r: rowId,
        });
    },
  },

  actions: {
    setCacheHeatmap(payload: { key: string; data: HeatmapItemType[] }) {
      this.cache.setItem(payload.key, payload.data);
    },

    setDatepickerSelectedOption(option: DatePickerOptionType) {
      useDatesStore().setSelectedOption(option);
    },

    setLastFetchedGrouping(grouping: FilterKeyEnum) {
      this.lastFetchedGrouping = grouping;
    },

    setGrouping(grouping: FilterKeyEnum) {
      this.grouping = grouping;
    },

    setFilters(newFilters: FilterCriteria) {
      this.filters = newFilters;
    },
  },
});
