import { SentryErrorReporter } from './vendors/sentry.error-reporter';
import { ConsoleErrorReporter } from '~/packages/error-reporter/vendors/console.error-reporter';

export interface ErrorReporter {
  log: (err: Error) => void;
  setUser: (user: User) => void;
}

export type User = null | { id: string };

let errorReporter: undefined | ErrorReporter;

export function getErrorReporter(): ErrorReporter {
  if (!errorReporter) {
    const app = useNuxtApp();
    const router = useRouter();
    const config = useRuntimeConfig();

    if (config.public.isProduction) {
      errorReporter = new SentryErrorReporter({
        vueApp: app.vueApp,
        vueRouter: router,
        dsn: config.public.sentryDsn,
        release: config.public.sentryRelease,
        enabled: config.public.isProduction,
      });
    } else {
      errorReporter = new ConsoleErrorReporter();
    }
  }

  return errorReporter;
}
