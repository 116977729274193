import permissionGlobalEnums from './permission/permission.global.enums';
import permissionAttritionEnums from './permission/permission.attrition.enums';
import permissionSegmentEnums from './permission/permission.segment.enums';
import permissionProductivityEnums from './permission/permission.productivity.enums';
import permissionIntegrationEnums from './permission/permission.integration.enums';
import permissionSurveyEnums from './permission/permission.survey.enums';
import permissionPeopleEnums from './permission/permission.people.enums';
import permissionRevenueEnums from './permission/permission.revenue.enums';
import permissionProfileEnums from '~/packages/user/enums/permission/permission.profile.enums';

export const PermissionEnum = {
  ...permissionGlobalEnums,
  ...permissionAttritionEnums,
  ...permissionSegmentEnums,
  ...permissionProductivityEnums,
  ...permissionIntegrationEnums,
  ...permissionSurveyEnums,
  ...permissionPeopleEnums,
  ...permissionRevenueEnums,
  ...permissionProfileEnums,
};
