import { FilterOptionType } from '@/packages/filter/types/filter-option.type';
import { FilterKeyEnum } from '@/packages/filter/enums/filter-key.enum';
import { FilteredUsersType } from '@/packages/filter/types/filtered-users.type';

export class FilterFakeService {
  public static getFilters(): FilterOptionType[] {
    return [
      {
        name: 'Division',
        uid: FilterKeyEnum.DIVISION,
        items: [
          { id: 2, name: 'Business', users: 6, worksNow: 6 },
          { id: 1, name: 'Engineering', users: 13, worksNow: 6 },
        ],
      },
      {
        name: 'Department',
        uid: FilterKeyEnum.DEPARTMENT,
        items: [
          { id: 1, name: 'Sales', users: 13, worksNow: 6 },
          { id: 2, name: 'Engineering', users: 6, worksNow: 6 },
          { id: 3, name: 'HR', users: 6, worksNow: 6 },
          { id: 4, name: 'Marketing', users: 6, worksNow: 6 },
        ],
      },
      {
        name: 'Position',
        uid: FilterKeyEnum.POSITION,
        items: [
          { id: 1, name: '1 line position', users: 11450, worksNow: 11450 },
          { id: 2, name: '2 line position', users: 2750, worksNow: 2750 },
          { id: 3, name: '3 line position', users: 1325, worksNow: 1325 },
          { id: 4, name: '4 line position', users: 1325, worksNow: 1325 },
          { id: 5, name: '5 line position', users: 925, worksNow: 925 },
          { id: 6, name: '6 line position', users: 915, worksNow: 915 },
        ],
      },
      {
        name: 'Country',
        uid: FilterKeyEnum.COUNTRY,
        items: [
          { id: 1, name: 'USA', users: 254, worksNow: 11450 },
          { id: 2, name: 'Remote', users: 23, worksNow: 2750 },
          { id: 3, name: 'Not set', users: 23, worksNow: 1325 },
        ],
      },
      {
        name: 'City',
        uid: FilterKeyEnum.CITY,
        items: [
          { id: 'qwerty', name: 'New York', users: 254, worksNow: 11450 },
          { id: 2, name: 'San Francisco', users: 23, worksNow: 2750 },
          { id: 3, name: 'Denver', users: 23, worksNow: 1325 },
          { id: 4, name: 'Austin', users: 23, worksNow: 1325 },
        ],
      },
      // {
      //   name: 'Generation',
      //   uid: FilterKeyEnum.GENERATION,
      //   items: [
      //     {
      //       id: 1,
      //       name: 'Z or Zoomers (1997-2012)',
      //       users: 11450,
      //       worksNow: 11450,
      //     },
      //     {
      //       id: 2,
      //       name: 'Y or Millennials (1981-1996)',
      //       users: 2750,
      //       worksNow: 2750,
      //     },
      //     {
      //       id: 3,
      //       name: 'X or Xennials (1965-1980)',
      //       users: 1325,
      //       worksNow: 1325,
      //     },
      //     {
      //       id: 4,
      //       name: 'Baby boomers (1946-1964)',
      //       users: 1325,
      //       worksNow: 1325,
      //     },
      //     {
      //       id: 5,
      //       name: 'Not set',
      //       users: 1325,
      //       worksNow: 1325,
      //     },
      //   ],
      // },
      // {
      //   name: 'DEI',
      //   uid: FilterKeyEnum.DEI,
      //   items: [
      //     { id: 2, name: 'USA', users: 254, worksNow: 6 },
      //     { id: 1, name: 'Georgia', users: 23, worksNow: 6 },
      //   ],
      // },
      // {
      //   name: 'Gender',
      //   uid: FilterKeyEnum.GENDER,
      //   items: [
      //     { id: 1, name: 'Female', users: 6710, worksNow: 6710 },
      //     { id: 2, name: 'Male', users: 6410, worksNow: 6410 },
      //     { id: 3, name: 'Transgender', users: 23, worksNow: 23 },
      //     { id: 4, name: 'Non-binary', users: 16, worksNow: 16 },
      //   ],
      // },
      {
        name: 'Team by manager',
        uid: FilterKeyEnum.TEAM_MANAGER,
        items: [
          { id: 2, name: 'Gavin Belson', users: 6, worksNow: 100 },
          { id: 1, name: 'Richard Hendricks', users: 13, worksNow: 13 },
          { id: 3, name: 'Dinesh Chugtai', users: 6, worksNow: 6 },
          { id: 4, name: 'Jack Barker', users: 13, worksNow: 13 },
          { id: 5, name: 'Bertram Gilfoyle', users: 6, worksNow: 6 },
          { id: 5, name: '—', users: 6, worksNow: 6 },
          { id: 5, name: '—', users: 6, worksNow: 6 },
          { id: 5, name: '—', users: 6, worksNow: 6 },
          { id: 5, name: '—', users: 6, worksNow: 6 },
          { id: 5, name: '—', users: 6, worksNow: 6 },
          { id: 5, name: '—', users: 6, worksNow: 6 },
          { id: 5, name: '—', users: 6, worksNow: 6 },
          { id: 5, name: '—', users: 6, worksNow: 6 },
          { id: 5, name: '—', users: 6, worksNow: 6 },
          { id: 5, name: '—', users: 6, worksNow: 6 },
          { id: 5, name: '—', users: 6, worksNow: 6 },
          { id: 5, name: '—', users: 6, worksNow: 6 },
          { id: 5, name: '—', users: 6, worksNow: 6 },
          { id: 5, name: '—', users: 6, worksNow: 6 },
          { id: 5, name: '—', users: 6, worksNow: 6 },
          { id: 5, name: '—', users: 6, worksNow: 6 },
          { id: 5, name: '—', users: 6, worksNow: 6 },
          { id: 5, name: '—', users: 6, worksNow: 6 },
          { id: 5, name: '—', users: 6, worksNow: 6 },
          { id: 5, name: '—', users: 6, worksNow: 6 },
          { id: 5, name: '—', users: 6, worksNow: 6 },
          { id: 5, name: '—', users: 6, worksNow: 6 },
          { id: 5, name: '—', users: 6, worksNow: 6 },
          { id: 5, name: '—', users: 6, worksNow: 6 },
          { id: 5, name: '—', users: 6, worksNow: 6 },
          { id: 5, name: '—', users: 6, worksNow: 6 },
          { id: 5, name: '—', users: 6, worksNow: 6 },
          { id: 5, name: '—', users: 6, worksNow: 6 },
          { id: 5, name: '—', users: 6, worksNow: 6 },
          { id: 5, name: '—', users: 6, worksNow: 6 },
          { id: 5, name: '—', users: 6, worksNow: 6 },
          { id: 5, name: '—', users: 6, worksNow: 6 },
          { id: 5, name: '—', users: 6, worksNow: 6 },
          { id: 5, name: '—', users: 6, worksNow: 6 },
          { id: 5, name: '—', users: 6, worksNow: 6 },
          { id: 5, name: '—', users: 6, worksNow: 6 },
          { id: 5, name: '—', users: 6, worksNow: 6 },
          { id: 5, name: '—', users: 6, worksNow: 6 },
          { id: 5, name: '—', users: 6, worksNow: 6 },
          { id: 5, name: '—', users: 6, worksNow: 6 },
          { id: 5, name: '—', users: 6, worksNow: 6 },
          { id: 5, name: '—', users: 6, worksNow: 6 },
          { id: 5, name: '—', users: 6, worksNow: 6 },
          { id: 5, name: '—', users: 6, worksNow: 6 },
          { id: 5, name: '—', users: 6, worksNow: 6 },
          { id: 5, name: '—', users: 6, worksNow: 6 },
          { id: 5, name: '—', users: 6, worksNow: 6 },
          { id: 5, name: '—', users: 6, worksNow: 6 },
          { id: 5, name: '—', users: 6, worksNow: 6 },
          { id: 5, name: '—', users: 6, worksNow: 6 },
          { id: 5, name: '—', users: 6, worksNow: 6 },
          { id: 5, name: '—', users: 6, worksNow: 6 },
          { id: 5, name: '—', users: 6, worksNow: 6 },
          { id: 5, name: '—', users: 6, worksNow: 6 },
          { id: 5, name: '—', users: 6, worksNow: 6 },
          { id: 5, name: '—', users: 6, worksNow: 6 },
          { id: 5, name: '—', users: 6, worksNow: 6 },
          { id: 5, name: '—', users: 6, worksNow: 6 },
          { id: 5, name: '—', users: 6, worksNow: 6 },
          { id: 5, name: 'last one', users: 6, worksNow: 6 },
        ],
      },
      {
        name: 'Tenure',
        uid: FilterKeyEnum.TENURE,
        items: [
          { id: 1, name: '0-3 months tenure', users: 254, worksNow: 6 },
          { id: 2, name: '3-6 months tenure', users: 254, worksNow: 6 },
          { id: 3, name: '6-9 months tenure', users: 23, worksNow: 6 },
          { id: 4, name: '9-12 months tenure', users: 23, worksNow: 6 },
          { id: 5, name: '12-18 months tenure', users: 23, worksNow: 6 },
          { id: 6, name: '18-24 months tenure', users: 23, worksNow: 6 },
          { id: 7, name: '24-30 months tenure', users: 23, worksNow: 6 },
          { id: 8, name: '30-36 months tenure', users: 23, worksNow: 6 },
        ],
      },
      {
        name: 'Time since Promotion',
        uid: FilterKeyEnum.PROMOTION,
        items: [
          { id: 1, name: '0-3 months since promotion', users: 254, worksNow: 6 },
          { id: 2, name: '3-6 months since promotion', users: 254, worksNow: 6 },
          { id: 3, name: '6-9 months since promotion', users: 23, worksNow: 6 },
          { id: 4, name: '9-12 months since promotion', users: 23, worksNow: 6 },
          { id: 5, name: '12-18 months since promotion', users: 23, worksNow: 6 },
          { id: 6, name: '18-24 months since promotion', users: 23, worksNow: 6 },
          { id: 7, name: '24-30 months since promotion', users: 23, worksNow: 6 },
          { id: 8, name: '30-36 months since promotion', users: 23, worksNow: 6 },
        ],
      },
      {
        name: 'Pay increase',
        uid: FilterKeyEnum.PAY_INCREASE,
        items: [
          { id: 1, name: '0-3 months since pay increase', users: 254, worksNow: 6 },
          { id: 2, name: '3-6 months since pay increase', users: 254, worksNow: 6 },
          { id: 3, name: '6-9 months since pay increase', users: 23, worksNow: 6 },
          { id: 4, name: '9-12 months since pay increase', users: 23, worksNow: 6 },
          { id: 5, name: '12-18 months since pay increase', users: 23, worksNow: 6 },
          { id: 6, name: '18-24 months since pay increase', users: 23, worksNow: 6 },
          { id: 7, name: '24-30 months since pay increase', users: 23, worksNow: 6 },
          { id: 8, name: '30-36 months since pay increase', users: 23, worksNow: 6 },
        ],
      },
      {
        name: 'Top performers',
        uid: FilterKeyEnum.PERFORMANCE,
        items: [
          {
            id: 1,
            name: 'Top performers',
            users: 254,
            worksNow: 6,
          },
          {
            id: 2,
            name: 'Mid performers',
            users: 23,
            worksNow: 6,
          },
          {
            id: 3,
            name: 'Bottom performers',
            users: 23,
            worksNow: 6,
          },
        ],
      },
      {
        name: 'Quiet quitters',
        uid: FilterKeyEnum.QUIET_QUITTERS,
        items: [
          {
            id: 1,
            name: 'Quiet quitters',
            users: 254,
            worksNow: 6,
          },
          {
            id: 2,
            name: 'Non-quiet quitters',
            users: 23,
            worksNow: 6,
          },
        ],
      },
    ];
  }

  static getFilteredUsers(): Promise<FilteredUsersType> {
    return Promise.resolve({
      total: 1024,
      filtered: 1024,
    });
  }
}
